import React, { useState } from 'react';
import Container from '../Container';
import Icons from '../Icons';
import Text from '../Text';
import { TEXT_DS } from '../Text/constants';
import styled from '../styled';
import theme from '../theme';

//#region Styles

const LabelText = styled(Text)({
  marginBottom: '4px',
  color: `${theme.palette.activeGrey.main} !important`,
});

const DropdownContainer = styled(
  ({
    skinny,
    color,
    selected,
    ...rest
  }: {
    skinny: boolean;
    color: string;
    selected: boolean;
    [key: string]: any;
  }) => (
    <Container component="div" {...rest}>
      {rest.children}
    </Container>
  ),
)(
  ({
    skinny,
    color,
    selected,
  }: {
    skinny: boolean;
    color: string;
    selected: boolean;
  }) => ({
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px !important',
    height: skinny ? '50px' : '70px',
    backgroundColor: color
      ? color
      : selected
      ? `${theme.palette.common.white}`
      : `${theme.palette.barelyThereGrey.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
);

const OptionsContainer = styled('ul')({
  listStyle: 'none',
  margin: '0 !important',
  padding: '0 !important',
  width: '100%',
  backgroundColor: 'white',
  filter: 'drop-shadow(0px 8px 16px rgba(26, 26, 26, 0.16))',
});

const OptionsItem = styled('li')(({ selected }: { selected: boolean }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '57px',
  backgroundColor: selected
    ? `${theme.palette.lightOrangeTint.main}`
    : `${theme.palette.common.white}`,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.lightOrangeTint.main,
  },
}));

const OptionsDivider = styled('div')({
  height: '1px',
  backgroundColor: theme.palette.greyLight.main,
  margin: '0px 16px',
});

const OptionText = styled(Text)({
  color: `${theme.palette.primary.main} !important`,
  margin: '16px',
});

//#region Component

type OptionType = {
  value: number | string | boolean;
  displayValue?: string;
};

type DropdownMenuProps = {
  label?: string;
  type?: 'primary' | 'skinny';
  className?: string;
  color?: string;
  selectedOptionText?: string;
  selectedValue: number | string | boolean;
  setSelectedValue: React.Dispatch<
    React.SetStateAction<number | string | boolean>
  >;
  options: OptionType[];
};

export default function ChunkyDropdown(props: DropdownMenuProps) {
  const {
    className = '',
    color,
    label,
    options,
    selectedOptionText,
    selectedValue,
    type,
    setSelectedValue,
  } = props;

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleToggleDropDown = () => {
    setOpenDropdown(!openDropdown);
  };

  const optionsWithHandlers = options.map((option: OptionType) => {
    const value = option.value;
    const handler = () => {
      setSelectedValue(value);
      handleToggleDropDown();
    };

    return {
      ...option,
      handler,
    };
  });

  const selectedOptionDisplay =
    selectedOptionText ??
    options.find((o) => o.value === selectedValue)?.displayValue ??
    options.find((o) => o.value === selectedValue)?.value ??
    '';

  //#region HTML

  return (
    <>
      {label && (
        <LabelText
          className="ChunkyDropdown_Label"
          variant="custom"
          default={TEXT_DS.TINY_SEMIBOLD_12}
        >
          {label}
        </LabelText>
      )}
      <DropdownContainer
        disableGutters
        onClick={handleToggleDropDown}
        skinny={type === 'skinny'}
        color={color}
        selected={!!selectedValue}
        className={'DropdownContainer ' + className}
      >
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          {selectedOptionDisplay}
        </Text>
        <Icons.ArrowDropDownCircleIcon />
      </DropdownContainer>
      {openDropdown && (
        <OptionsContainer className="ChunkyDropdown_Options options-container">
          {optionsWithHandlers.map((o) => (
            <OptionsItem
              onClick={o.handler}
              selected={selectedValue === o.value}
              key={String(o.value || o.displayValue)}
            >
              <OptionText variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                {o.displayValue || o.value}
              </OptionText>
              <OptionsDivider />
            </OptionsItem>
          ))}
        </OptionsContainer>
      )}
    </>
  );
}
